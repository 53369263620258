<template>
  <b-card-actions ref="card" title="" action-refresh @refresh="refresh">
    <b-row>
      <b-col cols="8">
        <b-tabs v-model="tabIndex">
          <b-tab>
            <template #title>
              <feather-icon icon="HomeIcon"/>
              <span>  {{ $t('Fulfillment') }}
</span>
            </template>

            <div>
              <!-- User Info: Input Fields -->
              <b-form>
                <b-row>

                  <!-- Field: Description -->
                  <b-col
                      cols="12"
                      md="12"
                  >
                    <b-form-group
                        :label="$t('Description')"
                        label-for="description"
                    >
                      <b-form-input
                          :disabled="allEditDisabled"
                          id="description"
                          v-model="fulfillment.description"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row v-if="false">
                  <b-col
                      cols="12"
                      md="12"
                  >
                    <b-form-group
                        :label="$t('Legal Regulation')"
                        label-for="legal_regulation"
                    >

                      <vue-autosuggest
                          :disabled="allEditDisabled"
                          :suggestions="filteredOptions"
                          :limit="10"
                          :input-props="{id:'autosuggest__input',class:'form-control'}"
                          id="legal_regulation"
                          @input="onInputChange"
                          @selected="onSuggestionSelection"
                          v-model="legal_regulation_title"
                          disabled="true"
                      >
                        <template slot-scope="{suggestion}">
                          <span class="my-suggestion-item">{{ suggestion.item.title }}</span>
                        </template>
                      </vue-autosuggest>


                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row>

                  <!-- Field: Type -->
                  <b-col
                      cols="10"
                      md="10"
                  >
                    <b-form-group
                        :label="$t('Type')"

                        label-for="type"
                    >

                      <b-dropdown
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          :text="$t(fulfillment.fulfillment_type)"
                          variant="outline-primary"
                          class="button-inline-spacing-2"
                          block
                          :disabled="allEditDisabled"
                      >
                        <b-dropdown-item
                            v-for="type in types"
                            :key="type"
                            @click="setType(type)"
                        >
                          <span class="ml-50">{{ $t(type) }}</span>
                        </b-dropdown-item>

                      </b-dropdown>


                    </b-form-group>
                  </b-col>

                  <!-- archived -->
                  <b-col
                      cols="2"
                      md="2"
                  >
                    <b-form-group
                        :label="$t('Status')"
                        label-for="archived"
                    >
                      <b-form-checkbox
                          v-model="archived"
                          name="terminated"
                          id="terminated"
                          switch
                          inline
                          class="mt-06 custom-control-danger"
                          :disabled="allEditDisabled"

                      >
                        {{ archived_label }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-if="fulfillment.fulfillment_type === 'periodic'">

                  <!-- Field: Frequency -->
                  <b-col
                      cols="12"
                      md="12"
                  >
                    <b-form-group
                        :label="$t('Frequency in days')"
                        label-for="frequency"
                    >
                      <b-form-input
                          :disabled="allEditDisabled"
                          id="description"
                          v-model="fulfillment.frequency"
                          type="number"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-form>


              <!-- Action Buttons -->
              <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click.prevent.stop="handleSubmit()"
                  :disabled="allEditDisabled"
              >
                {{ $t('Save') }}
              </b-button>
              <b-button
                  variant="outline-secondary"
                  type="reset"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click.prevent.stop="back($event)"
              >
                {{ $t('Cancel') }}


              </b-button>
            </div>


          </b-tab>


        </b-tabs>
      </b-col>
      <b-col cols="4">
        <b-card
            :title="legal_regulation.title"
            class="mb-3"
        >
          <b-card-text>
            <dl class="row">
              <dt class="col-sm-3">
                {{ $t('Area') }}
              </dt>
              <dd class="col-sm-9">
                {{ legal_regulation.area }}
              </dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-3">
                {{ $t('field') }}
              </dt>
              <dd class="col-sm-9">
                {{ legal_regulation.field }}
              </dd>
            </dl>

          </b-card-text>
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :href="legal_regulation.link"
              target="_blank"
              v-if="false"
          >
            {{ $t('Link') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-card-actions>


</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BCardText,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BFormTextarea,
  BTable,
  BTabs,
  BTab,
  BCardHeader,
  BDropdown,
  BDropdownItem,

  BCardTitle, BFormFile,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import Ripple from 'vue-ripple-directive'
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";
import {codeI18n} from "@/views/table/vue-good-table/code";
import {useRouter} from "@core/utils/utils";
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {BCardActions} from "@core/components/b-card-actions";
import i18n from '@/libs/i18n'
import Spacer from "@/views/components/spacer/Spacer";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import EquipmentService from "@/basic/equipmentService";
import LegalService from "@/basic/legalService";
import useUsersList from "@/views/apps/user/users-list/useUsersList";
import {ref} from "@vue/composition-api";
import {avatarText} from "@core/utils/filter";
import vSelect from 'vue-select'
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import ability from "@/libs/acl/ability";
import {VueAutosuggest} from 'vue-autosuggest'

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BAvatar,
    BCard,
    BFormTextarea,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardActions,
    BMedia,
    BTabs,
    BDropdown,
    BTab,
    BDropdownItem,
    flatPickr,
    Spacer,
    vSelect,
    BFormFile,
    Cropper,
    VueAutosuggest,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      fulfillment: {
        description: "",
        fulfillment_type: "",
        status: "",
        legal_regulation: {title: ""},
        legal_regulation_id: 0
      },
      userData: JSON.parse(localStorage.getItem('userData')),
      userCompany: JSON.parse(localStorage.getItem('userCompany')),
      editDisabled: false,
      allEditDisabled: !ability.can("manage", "Basic Admin"),
      department_new_id: 1,
      role_new_id: 1,
      status_list: ["open", "to_be_started", "suspended", "closed"],
      types: ["initial", "periodic"],
      dateDefault: null,
      assignment_options: ['a', 'b', 'c'],
      assignment_selected: [],
      url: "",
      variant: "light",
      archived: false,

      tab: this.$route.params.tab ? this.$route.params.tab : "main",
      tabIndex: 1,
      suggestions: [],
      filteredOptions: [],
      limit: 10,
      selected: null,
      copy_from: this.$route.params.copy_from ? this.$route.params.copy_from : "none",
      copy_from_fulfillment:null,

      legal_regulations: [],
      legal_regulation_title: "",
      legal_regulation: {area: '', title: "", link: "", field: "", type: null},
      from_company: false,
      from_company_id: 0,
    }
  },
  computed: {
    archived_label() {
      return (this.archived) ? i18n.t('Archived') : i18n.t('Not archived');
    },
  },
  methods: {

    onSuggestionSelection(selectedItem) {
      console.log('selectedItem', selectedItem)
      this.fulfillment.legal_regulation_id = selectedItem.item.id
      this.legal_regulation_title = selectedItem.item.title
      this.legal_regulation = selectedItem.item
    },
    onInputChange(text) {

      console.log("onInputChange Text", text)
      console.log("onInputChange filteredOptions", this.filteredOptions)
      if (text === '' || text === undefined) {
        this.filteredOptions = [{
          data: this.legal_regulations,
        }]
      }

      const filteredData = this.legal_regulations.filter(item => item.title.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      this.filteredOptions = [{
        data: filteredData,
      }]
    },

    refresh() {


      const legalService = new LegalService(useJwt)

      let fulfillment_id = this.$route.params.id;
      console.log("refresh fulfillment_id", fulfillment_id)
      let legal_regulation_id = this.$route.params.legal_regulation_id;
      console.log("refresh legal_regulation_id", legal_regulation_id)
      let type = this.$route.params.type;
      console.log("refresh type", type)

      console.log("refresh copy_from", this.copy_from)

      this.from_company_id = this.$route.params.from_company_id;
      if (this.from_company_id) {
        this.from_company = true;
      }

      console.log("refresh from_company_id", this.from_company_id)

      if (this.copy_from !== "none") {
        legalService.fulfillment(this.copy_from).then(response => {
          if (response.data.data.fulfillment) {
            this.copy_from_fulfillment = response.data.data.fulfillment
            this.fulfillment.description = this.copy_from_fulfillment.description
          }
          else{

          }

        });

      }
      this.$refs["card"].showLoading = true

      if (fulfillment_id !== 'new'){
        legalService.fulfillment(fulfillment_id).then(response => {


          if (response.data.data.fulfillment) {
            console.log("fulfillment", response.data.data.fulfillment)
            this.documents_enabled = true;
            this.fulfillment = response.data.data.fulfillment;
            if (this.fulfillment.archived_at) {
              this.archived = true;
            }
            this.legal_regulation_title = this.fulfillment.legal_regulation.title
            this.legal_regulation = this.fulfillment.legal_regulation
          } else {
            console.log('fulfillment not found')
          }

          if (response.data.data.legal_regulations) {
            this.legal_regulations = response.data.data.legal_regulations;
            this.filteredOptions = [{data: this.legal_regulations,}];

          } else {
            console.log('legal_regulations not found')
          }

          console.log('tabIndex s', this.tabIndex)

          console.log('data', response.data.data)
          this.$refs["card"].showLoading = false
          console.log("refresh filteredOptions", this.filteredOptions)

        })

      }
      else{

        legalService.legal_regulation(legal_regulation_id).then(response => {
          console.log("legal_regulation", response.data.data.legal_regulation)
          this.legal_regulation = response.data.data.legal_regulation
          this.legal_regulation_title = this.legal_regulation.title
          this.legal_regulations = [this.legal_regulation]
          this.filteredOptions = [{data: this.legal_regulations,}];
          this.$refs["card"].showLoading = false
          console.log("refresh filteredOptions", this.filteredOptions)
        })

        this.fulfillment.fulfillment_type = type;
        this.$refs["card"].showLoading = false

      }


    },
    async back(e) {
      if (this.from_company){
        this.$router.push({
          name: 'company-fulfillments',
          params: {'legal_regulation_id': this.legal_regulation.id, 'company_id': this.from_company_id}
        })
      }
      else{
        this.$router.push({name: 'fulfillments'})

      }
    },
    setStatus(status) {
      this.fulfillment.status = status;
    },
    setType(type) {
      this.fulfillment.fulfillment_type = type;
    },


    async handleSubmit(e) {
      const legalService = new LegalService(useJwt)
      this.submitted = true;
      //const valid = await this.$validator.validate();
      const valid = true;

      if (!valid) {
        this.$vs.notify({
          title: this.$t("create_admin.FailedToCreateAdmin"),
          text: this.$t("create_admin.ConfirmPasswordFailed"),
          color: "danger",
          position: "top-center"
        });
        this.submitted = false;
        return false;
      }

      this.$refs["card"].showLoading = true
      this.fulfillment.legal_regulation = this.legal_regulation
      this.fulfillment.legal_regulation_id = this.legal_regulation.id
      const data = {
        fulfillment: this.fulfillment,
        archived: this.archived,
        from_company: this.from_company_id,
      };


      try {
        console.log('data', data)
        let fulfillment_id = this.$route.params.id;

        legalService.update_fulfillment(fulfillment_id, data).then(response => {
          console.log("response", response)
          this.$refs["card"].showLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('Fulfillment'),
              icon: 'AlertIcon',
              variant: 'success',
              text: i18n.t('Fulfillment saved with success'),
            },
          })
          if (this.from_company){
            this.$router.push({
              name: 'company-fulfillments',
              params: {'legal_regulation_id': this.legal_regulation.id, 'company_id': this.from_company_id}
            })
          }
          else{
            this.$router.push({name: 'fulfillments'})

          }
        })

        const response = {status: 200, data: {id: 1}}


      } catch (error) {

      }
      this.submitted = false;
    },

  },
  mounted() {
    this.refresh()
  },
  setup(props) {
    const {resolveUserRoleVariant} = useUsersList()
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.avatar = base64
      console.log("avatar", props.avatar)
    })

    return {
      resolveUserRoleVariant,
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
