import {environment} from "@/auth/environment";

export default class LegalService {
    // Will be used by this service for making API calls
    axiosIns = null
    useJwt = null
    jwtConfig = null


    constructor(useJwt) {
        this.useJwt = useJwt
        this.jwtConfig = useJwt.jwtConfig
        this.axiosIns = useJwt.axiosIns

    }


    legal_regulations(...args) {
        return this.axiosIns.get(environment.baseURL + "/legal/getLegalRegulations/" , ...args)
    }

    legal_regulation(legal_regulation_id) {
        return this.axiosIns.get(environment.baseURL + "/legal/getLegalRegulation/" + legal_regulation_id )
    }

    update_legal_regulation(legal_regulation_id, data) {
        return this.axiosIns.post(environment.baseURL + "/legal/updateLegalRegulation/" + legal_regulation_id, data)
    }

    delete_legal_regulation(legal_regulation_id, data) {
        return this.axiosIns.get(environment.baseURL + "/legal/deleteLegalRegulation/" + legal_regulation_id, data)
    }

    fulfillments(company_id,...args) {
        return this.axiosIns.get(environment.baseURL + "/legal/getFulfillments" , ...args)
    }

    fulfillment(fulfillment_id) {
        return this.axiosIns.get(environment.baseURL + "/legal/getFulfillment/" + fulfillment_id )
    }

    update_fulfillment(fulfillment_id, data) {
        return this.axiosIns.post(environment.baseURL + "/legal/updateFulfillment/" + fulfillment_id, data)
    }

    delete_fulfillment(fulfillment_id, data) {
        return this.axiosIns.get(environment.baseURL + "/legal/deleteFulfillment/" + fulfillment_id, data)
    }


    company_legal_regulations(company_id,...args) {
        return this.axiosIns.get(environment.baseURL + "/legal/getCompanyLegalRegulations/"+company_id , ...args)
    }

    company_legal_regulation(legal_regulation_id) {
        return this.axiosIns.get(environment.baseURL + "/legal/getCompanyLegalRegulation/" + legal_regulation_id )
    }

    add_company_legal_regulations(company_id, data) {
        return this.axiosIns.post(environment.baseURL + "/legal/addCompanyLegalRegulations/" + company_id, data)
    }

    company_fulfillments(company_id, legal_regulation_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/legal/getCompanyFulfillments/"+company_id +"/"+legal_regulation_id, ...args)
    }


    company_fulfillment(company_fulfillment_id) {
        return this.axiosIns.get(environment.baseURL + "/legal/getCompanyFulfillment/" + company_fulfillment_id )
    }

    update_company_fulfillment(company_fulfillment_id, data) {
        return this.axiosIns.post(environment.baseURL + "/legal/updateCompanyFulfillment/" + company_fulfillment_id, data)
    }

    company_fulfillment_documents(company_fulfillment_id,...args) {
        return this.axiosIns.get(environment.baseURL + "/legal/getCompanyFulfillmentDocuments/"+company_fulfillment_id , ...args)
    }

    company_fulfillment_document(company_fulfillment_document_id) {
        return this.axiosIns.get(environment.baseURL + "/legal/getCompanyFulfillmentDocument/" + company_fulfillment_document_id )
    }

    update_company_fulfillment_document(company_fulfillment_document_id, data) {
        return this.axiosIns.post(environment.baseURL + "/legal/updateCompanyFulfillmentDocument/" + company_fulfillment_document_id, data)

    }

    delete_company_fulfillment_document(company_fulfillment_document_id, data) {
        return this.axiosIns.post(environment.baseURL + "/legal/deleteCompanyFulfillmentDocument/" + company_fulfillment_document_id, data)
    }
}
